import React, { useEffect,useState } from "react";
import logo from "./hassan-documents/bluelogo.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./CourseTenses.css";

import pdfUrls from "./hassan-documents/Present simple summary.pdf";
import CourseGrammarVideo from "./ACADEMYVIDEOS/vocabulary/gifts of youth 2026 last edit.mp4";
import gramimg1 from "./book_pages/Grammar/G1.jpg";
import gramimg2 from "./book_pages/Grammar/G2.jpg";
import gramimg3 from "./book_pages/Grammar/G3.jpg";
import gramimg4 from "./book_pages/Grammar/G4.jpg";
import gramimg5 from "./book_pages/Grammar/G5.jpg";
import gramimg6 from "./book_pages/Grammar/G6.jpg";
import gramimg7 from "./book_pages/Grammar/G7.jpg";
import gramimg8 from "./book_pages/Grammar/G8.jpg";
import gramimg9 from "./book_pages/Grammar/G9.jpg";
import gramimg10 from "./book_pages/Grammar/G10.jpg";
import gramimg11 from "./book_pages/Grammar/G11.jpg";
import gramimg12 from "./book_pages/Grammar/G12.jpg";
import gramimg13 from "./book_pages/Grammar/G13.jpg";
import gramimg14 from "./book_pages/Grammar/G14.jpg";
import gramimg15 from "./book_pages/Grammar/G15.jpg";
import gramimg16 from "./book_pages/Grammar/G16.jpg";
import gramimg17 from "./book_pages/Grammar/G17.jpg";
import gramimg18 from "./book_pages/Grammar/G18.jpg";
import gramimg19 from "./book_pages/Grammar/G19.jpg";
import gramimg20 from "./book_pages/Grammar/G20.jpg";
import gramimg21 from "./book_pages/Grammar/G21.jpg";
import gramimg22 from "./book_pages/Grammar/G22.jpg";
import gramimg23 from "./book_pages/Grammar/G23.jpg";
import gramimg24 from "./book_pages/Grammar/G24.jpg";
import gramimg25 from "./book_pages/Grammar/G25.jpg";
import gramimg26 from "./book_pages/Grammar/G26.jpg";
import gramimg27 from "./book_pages/Grammar/G27.jpg";
import gramimg28 from "./book_pages/Grammar/G28.jpg";
const GramImages = [
  gramimg1,
  gramimg2,
  gramimg3,
  gramimg4,
  gramimg5,
  gramimg6,
  gramimg7,
  gramimg8,
  gramimg9,
  gramimg10,
  gramimg11,
  gramimg12,
  gramimg13,
  gramimg14,
  gramimg15,
  gramimg16,
  gramimg17,
  gramimg18,
  gramimg19,
  gramimg20,
  gramimg21,
  gramimg22,
  gramimg23,
  gramimg24,
  gramimg25,
  gramimg26,
  gramimg27,
  gramimg28,
];

function CourseGrammar() {
  const [currentIndex, setCurrentIndex] = useState(0);
   const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % GramImages.length);
    };
  
    const prevSlide = () => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + GramImages.length) % GramImages.length
      );
    };
  
    const [isScale, setIsScale] = useState(true);
  
    function handleDoubleClick() {
      if (isScale) {
        document.querySelector(".slider-container").style.transform =
          "scale(2.5)";
        document.querySelector(".slider-container").style.marginTop = "400px";
        setIsScale(!isScale);
        //   document.querySelector(".slider-image").style.height = "100vh";
  
        //   document.querySelector(".slider-image").style.position = "absolute";
        // setIsScale(false)
      } else {
        document.querySelector(".slider-container").style.transform = "scale(1)";
        document.querySelector(".slider-container").style.marginTop = "50px";
        setIsScale(!isScale);
        // setIsScale(true)
      }
    }
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
  const onButtonClick = () => {
    const pdfUrl = pdfUrls;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="CourseGrammar">
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="tenses__page">
        <h2 className="tenses__title">Gerund and Infinitive</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Gerund and Infinitive - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Gerund and Infinitive - exercises</div>
          </div>
        </div>

        {/* present Continuous */}
        <h2 className="tenses__title"> Expressing purpose</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Expressing purpose - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Expressing purpose - exercises</div>
          </div>
        </div>

        {/* present perfect */}
        <h2 className="tenses__title">Expressing regret</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Expressing regret - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Expressing regret - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Relative pronouns</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Relative pronouns - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Relative pronouns - exercises</div>
          </div>
        </div>

        {/* Past Continuous */}
        <h2 className="tenses__title"> Prefixes and suffixes</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Prefixes and suffixes - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Prefixes and suffixes - exercises</div>
          </div>
        </div>

        {/* Past perfect */}
        <h2 className="tenses__title">Phrasal verbs</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Phrasal verbs - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Phrasal verbs - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Passive voice</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Passive voice - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Passive voice - exercises</div>
          </div>
        </div>

        {/* Future Continuous */}
        <h2 className="tenses__title"> wish and if only</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>wish and if only - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>wish and if only - exercises</div>
          </div>
        </div>

        {/* Future perfect */}
        <h2 className="tenses__title">Conditional type 3</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Conditional type 3 - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Conditional type 3 - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Modal verbs</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Modal verbs part 1 - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Modal verbs part 2 - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Modal verbs part 3 - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Modal verbs - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Reported speech</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Reported speech part 1 - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Reported speech part 2 - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Reported speech part 3 - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseGrammarVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Reported speech - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
      </div>
      <h1 className="functions__summary">Grammar summary</h1>
      <div
        className="slider-container"
        id="functions__slide"
        style={{ backgroundColor: "f1f1f1" }}
      >
        <div className="arrow left" onClick={prevSlide}>
          ❰
        </div>
        <img
          onDoubleClick={() => handleDoubleClick()}
          src={GramImages[currentIndex]}
          alt="slider"
          onContextMenu={handleContextMenu}
          className="slider-image"
        />
        <div className="arrow right" onClick={nextSlide}>
          ❱
        </div>
      </div>

      {/* past */}
    </div>
  );
}

export default CourseGrammar;
