import React, { useEffect, useState } from "react";
import logo from "./hassan-documents/bluelogo.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./CourseTenses.css";

import pdfUrls from "./hassan-documents/Present simple summary.pdf";
import CourseWritingVideo from "./ACADEMYVIDEOS/vocabulary/gifts of youth 2026 last edit.mp4";
import writingimg1 from "./book_pages/Writing/W1.jpg";
import writingimg2 from "./book_pages/Writing/W2.jpg";
import writingimg3 from "./book_pages/Writing/W3.jpg";
import writingimg4 from "./book_pages/Writing/W4.jpg";
import writingimg5 from "./book_pages/Writing/W5.jpg";
import writingimg6 from "./book_pages/Writing/W6.jpg";
import writingimg7 from "./book_pages/Writing/W7.jpg";
import writingimg8 from "./book_pages/Writing/W8.jpg";
import writingimg9 from "./book_pages/Writing/W9.jpg";
import writingimg10 from "./book_pages/Writing/W10.jpg";
import writingimg11 from "./book_pages/Writing/W11.jpg";
import writingimg12 from "./book_pages/Writing/W12.jpg";
import writingimg13 from "./book_pages/Writing/W13.jpg";
import writingimg14 from "./book_pages/Writing/W14.jpg";
import writingimg15 from "./book_pages/Writing/W15.jpg";
import writingimg16 from "./book_pages/Writing/W16.jpg";
import writingimg17 from "./book_pages/Writing/W17.jpg";
import writingimg18 from "./book_pages/Writing/W18.jpg";
import writingimg19 from "./book_pages/Writing/W19.jpg";




const writingImages = [
  writingimg1,
  writingimg2,
  writingimg3,
  writingimg4,
  writingimg5,
  writingimg6,
  writingimg7,
  writingimg8,
  writingimg9,
  writingimg10,
  writingimg11,
  writingimg12,
  writingimg13,
  writingimg14,
  writingimg15,
  writingimg16,
  writingimg17,
  writingimg18,
  writingimg19,
 
];
function CourseWriting() {
   const [currentIndex, setCurrentIndex] = useState(0);
       const nextSlide = () => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % writingImages.length);
        };
      
        const prevSlide = () => {
          setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + writingImages.length) % writingImages.length
          );
        };
      
        const [isScale, setIsScale] = useState(true);
      
        function handleDoubleClick() {
          if (isScale) {
            document.querySelector(".slider-container").style.transform =
              "scale(2.5)";
            document.querySelector(".slider-container").style.marginTop = "400px";
            setIsScale(!isScale);
            //   document.querySelector(".slider-image").style.height = "100vh";
      
            //   document.querySelector(".slider-image").style.position = "absolute";
            // setIsScale(false)
          } else {
            document.querySelector(".slider-container").style.transform = "scale(1)";
            document.querySelector(".slider-container").style.marginTop = "50px";
            setIsScale(!isScale);
            // setIsScale(true)
          }
        }
        const handleContextMenu = (event) => {
          event.preventDefault();
        };
  const onButtonClick = () => {
    const pdfUrl = pdfUrls;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="CourseWriting">
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="tenses__page">
        <h2 className="tenses__title">Linking words</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Linking words : Addition</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Linking words : concession</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Linking words : cause and effect</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Linking words : Exercises</div>
          </div>
        </div>

        {/* present Continuous */}
        <h2 className="tenses__title"> Paragraph writing</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Paragraph writing - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Paragraph writing - exercises</div>
          </div>
        </div>

        {/* present perfect */}
        <h2 className="tenses__title">Descriptive Paragraph</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Descriptive Paragraph - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Descriptive Paragraph - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">A funny story</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>A funny story - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>A funny story - exercises</div>
          </div>
        </div>

        {/* Past Continuous */}
        <h2 className="tenses__title"> Writing a report</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Writing a report - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Writing a report - exercises</div>
          </div>
        </div>

        {/* Past perfect */}
        <h2 className="tenses__title">Formal email</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Formal email - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Formal email - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Informal email</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Informal email - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Informal email - exercises</div>
          </div>
        </div>

        {/* Future Continuous */}
        <h2 className="tenses__title"> Formal & informal letter</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Formal & informal letter - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Formal & informal letter - exercises</div>
          </div>
        </div>

        {/* Future perfect */}
        <h2 className="tenses__title">Review a film</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Review a film - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Review a film - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Review a book</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Review a book - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Review a book - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">Article : advantage and disadvantages</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Article : advantage and disadvantages - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Article : advantage and disadvantages - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">Article : causes and effects</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Article : causes and effects - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Article : causes and effects - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">Argumentative essay</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Argumentative essay - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Argumentative essay - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
      </div>
      <h1 className="functions__summary">Writing summary</h1>
      <div
        className="slider-container"
        id="functions__slide"
        style={{ backgroundColor: "f1f1f1" }}
      >
        <div className="arrow left" onClick={prevSlide}>
          ❰
        </div>
        <img
          onDoubleClick={() => handleDoubleClick()}
          src={writingImages[currentIndex]}
          alt="slider"
          onContextMenu={handleContextMenu}
          className="slider-image"
        />
        <div className="arrow right" onClick={nextSlide}>
          ❱
        </div>
      </div>

      {/* past */}
    </div>
  );
}

export default CourseWriting;
