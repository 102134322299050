import React, { useEffect, useState } from "react";
import logo from "./hassan-documents/bluelogo.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./CourseTenses.css";

import pdfUrls from "./hassan-documents/Present simple summary.pdf";
import CourseTensesVideo from "./ACADEMYVIDEOS/vocabulary/gifts of youth 2026 last edit.mp4";
import tenseimg1 from "./book_pages/Tenses/T1.jpg";
import tenseimg2 from "./book_pages/Tenses/T2.jpg";
import tenseimg3 from "./book_pages/Tenses/T3.jpg";
import tenseimg4 from "./book_pages/Tenses/T4.jpg";
import tenseimg5 from "./book_pages/Tenses/T5.jpg";
import tenseimg6 from "./book_pages/Tenses/T6.jpg";
import tenseimg7 from "./book_pages/Tenses/T7.jpg";
import tenseimg8 from "./book_pages/Tenses/T8.jpg";
import tenseimg9 from "./book_pages/Tenses/T9.jpg";
import tenseimg10 from "./book_pages/Tenses/T10.jpg";
import tenseimg11 from "./book_pages/Tenses/T11.jpg";
import tenseimg12 from "./book_pages/Tenses/T12.jpg";
import tenseimg13 from "./book_pages/Tenses/T13.jpg";
import tenseimg14 from "./book_pages/Tenses/T14.jpg";
import tenseimg15 from "./book_pages/Tenses/T15.jpg";
import tenseimg16 from "./book_pages/Tenses/T16.jpg";
import tenseimg17 from "./book_pages/Tenses/T17.jpg";
import tenseimg18 from "./book_pages/Tenses/T18.jpg";
import tenseimg19 from "./book_pages/Tenses/T19.jpg";
import tenseimg20 from "./book_pages/Tenses/T20.jpg";
import tenseimg21 from "./book_pages/Tenses/T21.jpg";
import tenseimg22 from "./book_pages/Tenses/T22.jpg";
import tenseimg23 from "./book_pages/Tenses/T23.jpg";
import tenseimg24 from "./book_pages/Tenses/T24.jpg";

const tenseImages = [
  tenseimg1,
  tenseimg2,
  tenseimg3,
  tenseimg4,
  tenseimg5,
  tenseimg6,
  tenseimg7,
  tenseimg8,
  tenseimg9,
  tenseimg10,
  tenseimg11,
  tenseimg12,
  tenseimg13,
  tenseimg14,
  tenseimg15,
  tenseimg16,
  tenseimg17,
  tenseimg18,
  tenseimg19,
  tenseimg20,
  tenseimg21,
  tenseimg22,
  tenseimg23,
  tenseimg24,
 
];
function CourseTenses() {
   const [currentIndex, setCurrentIndex] = useState(0);
     const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % tenseImages.length);
      };
    
      const prevSlide = () => {
        setCurrentIndex(
          (prevIndex) => (prevIndex - 1 + tenseImages.length) % tenseImages.length
        );
      };
    
      const [isScale, setIsScale] = useState(true);
    
      function handleDoubleClick() {
        if (isScale) {
          document.querySelector(".slider-container").style.transform =
            "scale(2.5)";
          document.querySelector(".slider-container").style.marginTop = "400px";
          setIsScale(!isScale);
          //   document.querySelector(".slider-image").style.height = "100vh";
    
          //   document.querySelector(".slider-image").style.position = "absolute";
          // setIsScale(false)
        } else {
          document.querySelector(".slider-container").style.transform = "scale(1)";
          document.querySelector(".slider-container").style.marginTop = "50px";
          setIsScale(!isScale);
          // setIsScale(true)
        }
      }
      const handleContextMenu = (event) => {
        event.preventDefault();
      };
  const onButtonClick = () => {
    const pdfUrl = pdfUrls;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="CourseTenses">
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="tenses__page">
        <h2 className="tenses__title">Simple present</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple present - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple present - exercises</div>
          </div>
        </div>

        {/* present Continuous */}
        <h2 className="tenses__title"> Present Continuous</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Present Continuous - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Present Continuous - exercises</div>
          </div>
        </div>

        {/* present perfect */}
        <h2 className="tenses__title">Present Perfect</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Present Perfect - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Present Perfect - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Simple Past</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple Past - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple Past - exercises</div>
          </div>
        </div>

        {/* Past Continuous */}
        <h2 className="tenses__title"> Past Continuous</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Past Continuous - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Past Continuous - exercises</div>
          </div>
        </div>

        {/* Past perfect */}
        <h2 className="tenses__title">Past Perfect</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Past Perfect - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Past Perfect - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Simple Future</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple Future - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple Future - exercises</div>
          </div>
        </div>

        {/* Future Continuous */}
        <h2 className="tenses__title"> Future Continuous</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Future Continuous - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Future Continuous - exercises</div>
          </div>
        </div>

        {/* Future perfect */}
        <h2 className="tenses__title">Future Perfect</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Future Perfect - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Future Perfect - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
      </div>
      <h1 className="functions__summary">Tenses summary</h1>
      <div
        className="slider-container"
        id="functions__slide"
        style={{ backgroundColor: "f1f1f1" }}
      >
        <div className="arrow left" onClick={prevSlide}>
          ❰
        </div>
        <img
          onDoubleClick={() => handleDoubleClick()}
          src={tenseImages[currentIndex]}
          alt="slider"
          onContextMenu={handleContextMenu}
          className="slider-image"
        />
        <div className="arrow right" onClick={nextSlide}>
          ❱
        </div>
      </div>

      {/* past */}
    </div>
  );
}

export default CourseTenses;
