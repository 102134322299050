import React, { useEffect, useState } from "react";
import logo from "./hassan-documents/bluelogo.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./CourseSlide.css";

import pdfUrls from "./hassan-documents/Present simple summary.pdf";
import CourseFunctionsVideo from "./ACADEMYVIDEOS/vocabulary/gifts of youth 2026 last edit.mp4";
import functionvideo0 from './courses/functions/expressing opinion 2025 (1080P_HD) (1).mp4'
import functionvideo1 from "./courses/functions/Asking for advice 2025 for academy(1080P_HD).mp4";
import functionvideo2 from "./courses/functions/asking for clarification for academy(1080P_HD).mp4";
import functionvideo10 from "./courses/functions/Asking for permission for academy(1080P_HD).mp4";
import functionvideo3 from "./courses/functions/Complaining and apologizing for academy(1080P_HD).mp4";
import functionvideo53 from "./courses/functions/expressing opinion 2025 for academy(1080P_HD).mp4";
import functionvideo4 from "./courses/functions/expressing uncertainty for academy(1080P_HD).mp4";
import functionvideo9 from "./courses/functions/Expressions of definition for academy(1080P_HD).mp4";
import functionvideo5 from "./courses/functions/Making invitation for academy(1080P_HD).mp4";
import functionvideo6 from "./courses/functions/Making request for academy(1080P_HD).mp4";
import functionvideo8 from "./courses/functions/Responding to good news 2025 for academy(1080P_HD).mp4";
import funcimg1 from "./book_pages/Functions/F1.jpg";
import funcimg2 from "./book_pages/Functions/F2.jpg";
import funcimg3 from "./book_pages/Functions/F3.jpg";
import funcimg4 from "./book_pages/Functions/F4.jpg";
import funcimg5 from "./book_pages/Functions/F5.jpg";
import funcimg6 from "./book_pages/Functions/F6.jpg";
import funcimg7 from "./book_pages/Functions/F7.jpg";
import funcimg8 from "./book_pages/Functions/F8.jpg";
import funcimg9 from "./book_pages/Functions/F9.jpg";
import funcimg10 from "./book_pages/Functions/F10.jpg";
import funcimg11 from "./book_pages/Functions/F11.jpg";
import funcimg12 from "./book_pages/Functions/F12.jpg";
import funcimg13 from "./book_pages/Functions/F13.jpg";
import funcimg14 from "./book_pages/Functions/F14.jpg";
import funcimg15 from "./book_pages/Functions/F15.jpg";
import funcimg16 from "./book_pages/Functions/F16.jpg";
import funcimg17 from "./book_pages/Functions/F17.jpg";
import funcimg18 from "./book_pages/Functions/F18.jpg";
import funcimg19 from "./book_pages/Functions/F19.jpg";
import funcimg20 from "./book_pages/Functions/F20.jpg";
import funcimg21 from "./book_pages/Functions/F21.jpg";
import funcimg22 from "./book_pages/Functions/F22.jpg";
import funcimg23 from "./book_pages/Functions/F23.jpg";
import funcimg24 from "./book_pages/Functions/F24.jpg";
import funcimg25 from "./book_pages/Functions/F25.jpg";
import funcimg26 from "./book_pages/Functions/F26.jpg";
import funcimg27 from "./book_pages/Functions/F27.jpg";
import funcimg28 from "./book_pages/Functions/F28.jpg";
const funcImages = [
  funcimg1,
  funcimg2,
  funcimg3,
  funcimg4,
  funcimg5,
  funcimg6,
  funcimg7,
  funcimg8,
  funcimg9,
  funcimg10,
  funcimg11,
  funcimg12,
  funcimg13,
  funcimg14,
  funcimg15,
  funcimg16,
  funcimg17,
  funcimg18,
  funcimg19,
  funcimg20,
  funcimg21,
  funcimg22,
  funcimg23,
  funcimg24,
  funcimg25,
  funcimg26,
  funcimg27,
  funcimg28,
];

function CourseFunctions() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % funcImages.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + funcImages.length) % funcImages.length
    );
  };

  const [isScale, setIsScale] = useState(true);

  function handleDoubleClick() {
    if (isScale) {
      document.querySelector(".slider-container").style.transform =
        "scale(2.5)";
      document.querySelector(".slider-container").style.marginTop = "400px";
      setIsScale(!isScale);
      //   document.querySelector(".slider-image").style.height = "100vh";

      //   document.querySelector(".slider-image").style.position = "absolute";
      // setIsScale(false)
    } else {
      document.querySelector(".slider-container").style.transform = "scale(1)";
      document.querySelector(".slider-container").style.marginTop = "50px";
      setIsScale(!isScale);
      // setIsScale(true)
    }
  }
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const onButtonClick = () => {
    const pdfUrl = pdfUrls;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="CourseFunctions">
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="tenses__page">
        <h2 className="tenses__title">
          Expressing opinion : agreeing and disagreeing
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo0}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Expressing opinion : agreeing and disagreeing</div>
          </div>
        </div>

        {/* present Continuous */}
        <h2 className="tenses__title"> Asking for advice</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo1}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Asking for advice</div>
          </div>
        </div>

        {/* present perfect */}
        <h2 className="tenses__title">Asking for clarification</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo2}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Asking for clarification</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Complaining and apologizing</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo3}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Complaining and apologizing</div>
          </div>
        </div>

        {/* Past Continuous */}
        <h2 className="tenses__title"> Expressing certainty and uncertainty</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo4}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Expressing certainty and uncertainty</div>
          </div>
        </div>

        {/* Past perfect */}
        <h2 className="tenses__title">
          Making invitation : accepting and refusing
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo5}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Making invitation : accepting and refusing</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">
          Making request : accepting and refusing
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo6}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Making request : accepting and refusing</div>
          </div>
        </div>

        {/* Future Continuous */}
        <h2 className="tenses__title">
          {" "}
          Making suggestion : accepting and refusing
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo3}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Making suggestion : accepting and refusing</div>
          </div>
        </div>
        {/* <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div> */}
        {/* Future perfect */}
        <h2 className="tenses__title">Responding to bad and good news</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo8}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Responding to bad and good news</div>
          </div>
        </div>
        {/* <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div> */}
        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">Expressions of definition</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo9}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Expressions of definition</div>
          </div>
        </div>
        {/* <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div> */}
        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">
          Asking for permission : accepting and refusing
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={functionvideo10}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>Asking for permission : accepting and refusing</div>
          </div>
        </div>
        {/* <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div> */}
        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">National exams 1</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>National exams 1</div>
          </div>
        </div>
        {/* <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div> */}
        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">National exam : 2</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video>
            <div>National exam : 2</div>
          </div>
        </div>
        {/* <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div> */}
        {/* <object data={pdfUrls} type=""></object> */}
      </div>
      <h1 className="functions__summary">Functions summary</h1>
      <div
        className="slider-container"
        id="functions__slide"
        style={{ backgroundColor: "f1f1f1" }}
      >
        <div className="arrow left" onClick={prevSlide}>
          ❰
        </div>
        <img
          onDoubleClick={() => handleDoubleClick()}
          src={funcImages[currentIndex]}
          alt="slider"
          onContextMenu={handleContextMenu}
          className="slider-image"
        />
        <div className="arrow right" onClick={nextSlide}>
          ❱
        </div>
      </div>

      {/* past */}
    </div>
  );
}

export default CourseFunctions;
