import React, { useEffect } from "react";
import Hero from "./Hero";
import About from "./About";
import StudentsOpinions from "./StudentsOpinions";
import Plan from "./Plan";
import Faq from "./Faq";
import Courses from "./Courses";
import Feedback from "./Feedback";
import WhyUs from "./WhyUs";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import Pricing from "./Pricing";

function Home() {
  // useEffect(() => {
  //   document.addEventListener("contextmenu", (event) => event.preventDefault());
  //   document.onkeydown = function (e) {
  //     if (
  //       e.keyCode == 123 ||
  //       (e.ctrlKey &&
  //         e.shiftKey &&
  //         (e.keyCode == "I".charCodeAt(0) || e.keyCode == "J".charCodeAt(0))) ||
  //       (e.ctrlKey && e.keyCode == "U".charCodeAt(0))
  //     ) {
  //       return false;
  //     }
  //   };
  // }, []);

  const { hash } = useLocation();

  try {
    window.onscroll = function () {
      const header = document.getElementById("header");
      const headerLogo = document.querySelector("#header img");

      if (window.scrollY > 50) {
        // Change 30 to your desired scroll distance
        header.style.background = "#253E56";
        headerLogo.style.width = "60px";
        header.style.padding = "10px 100px";
        header.style.transition = "background 0.3s ease-in-out";
      } else {
        if (window.innerWidth < "668") {
          headerLogo.style.width = "60px";
          header.style.background = "";
          header.style.padding = "20px 100px";
        } else {
          headerLogo.style.width = "110px";
          header.style.background = "";
        }
      }
    };
  } catch (error) {}

  useEffect(() => {
    const header = document.getElementById("header");
    const headerLogo = document.querySelector("#header img");
    if (window.location.href === "https://toustousacademy.com/") {
      header.style.background = "";
      headerLogo.style.width = "110px";
      header.style.padding = "20px 100px";
    }

    if (window.location.href === "https://toustousacademy.com/book") {
      header.style.background = "#252B34";
      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    }
    // headerLogo.style.width = "60px";
    // header.style.backgroundColor = "blue";
  }, []);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useEffect(() => {
    scrollToTop();
  });

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);
  useEffect(() => {
    // Fetching data from API
    // fetch('https://jsonplaceholder.typicode.com/posts')
    //  .then(response => response.json())
    //  .then(data => console.log(data))
    //  .catch(error => console.error('Error:', error))
    //  .finally(() => console.log('Fetch complete'))

    localStorage.setItem("username", "toustous");
    localStorage.setItem("password", "123");
  }, []);
  return (
    <div>
      <Hero />
      <Courses />
      <Feedback />
      <WhyUs />
      <Pricing />
      {/* <About /> */}
      {/* <StudentsOpinions/> */}

      <Faq />
      <Footer />
    </div>
  );
}

export default Home;
